<template>
  <div>
    <HMModalHeader
      :title="$t('MEDIA_LIBRARY.TITLE.INSTRUCTOR_DETAILS')"
      @close="$attrs._close()"
    />
    <div class="text-center">
      <HMPhotoIcon class="mb-3" :instructor="instructorLocal" size="150px" />
      <h2 class="mb-3">{{ instructorLocal.name }}</h2>
      <p v-html="getInstructorBio"></p>
    </div>
  </div>
</template>

<script>
import { sanitize } from '@/utils/utils';
import { useProductStore } from  '@/stores';

export default {
  name: 'InstructorDetailsModal',
  props: {
    instructor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    studioURL() {
      return this.$studioURL;
    },
    getInstructorBio() {
      if (!this.instructorLocal) return '';
      if (this.instructorLocal.bio_new)
        return sanitize(this.instructorLocal.bio_new.replace(/\n/g, '<br/>'));

      return sanitize(this.instructorLocal.bio);
    },
  },
  data() {
    return {
      instructorLocal: this.instructor,
    };
  },
  async mounted() {
    const studioURL = this.studioURL;
    const instructorId = this.instructor.id;
    this.instructorLocal = await useProductStore().getStudioInstructor({
      studioURL,
      instructorId,
    });
  },
};
</script>

<style scoped></style>
