<template>
  <div class="instructors-container">
    <div
      v-for="instructor in instructors"
      :key="instructor.id"
      class="instructor-image"
      @click="showInstructorBio(instructor)"
    >
      <HMPhotoIcon
        :class="{ pointer: clickable }"
        :instructor="instructor"
        :size="size"
        :title="instructor.name"
        v-tooltip="{
          content: instructor.name,
          delay: 100,
          placement: 'top',
          container: 'body',
        }"
      />
    </div>
  </div>
</template>

<script>
import InstructorDetailsModal from '@/components/media-library/InstructorDetailsModal';
import HMPhotoIcon from '@/components/common/HMPhotoIcon';

export default {
  name: 'HMInstructorsStack',
  components: {
    HMPhotoIcon
  },
  props: {
    instructors: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showInstructorBio(instructor) {      
      this.$modal.show(InstructorDetailsModal, { instructor: instructor });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.instructor-image {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-color: white;
  margin-right: -15px;
  vertical-align: middle;
  &:last-child {
    margin-right: 0;
  }
}

.instructors-container {
  white-space: nowrap;
}
</style>
